.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

html .styles_faq-row-wrapper__3vA1D {
  max-width: 700px;
  margin: auto;
}

html
  .styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c {
  /* background: red; */
  margin-bottom: 20px;
}

html
  .styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  color: #fff;
}

html
  .styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw
  svg {
  fill: #fff;
}

html
  .styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  color: #fff;
}

html
  .styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  color: #c1ba45;
  font-size: small;
}

html .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  border-bottom: none;
}

html
  .styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c {
  background: #39234b;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

html .styles_faq-row-wrapper__3vA1D h2 {
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}

.landing-h1 {
  line-height: 411px;
}
