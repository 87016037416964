@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --brand: #1a052b;
  --secondary: #c4bd47;
  --body: #1a052b;
  --accent: #242f63;
}

* {
  font-family: "Poppins", sans-serif;
  line-height: 31px;
  font-size: 1.1rem;
  /* transform: scale(1.02); */
}

html {
  background: var(--body);
  color: #fff;
  overflow-x: hidden;
}

button {
  white-space: nowrap;
}

.max-lines-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.max-lines-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-lines-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.max-lines-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.max-lines-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.max-lines-8 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
}

.auth-bg {
  /* height: calc(100vh - 60px); */
  /* background: linear-gradient(to left, #0000, #000000),
    url("https://res.cloudinary.com/dou9sjstz/image/upload/v1664554166/auth-page-bg_hx5p9o.png"); */
  background: linear-gradient(rgba(26, 5, 43, 0.58), rgba(26, 5, 43, 0.58)),
    url(https://res.cloudinary.com/dou9sjstz/image/upload/v1664554166/auth-page-bg_hx5p9o.png);
}

.landing-overlay {
  background: linear-gradient(rgba(26, 5, 43, 0.58), rgba(26, 5, 43, 0.58));
}

.banner-gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* margin-top: 100px; */
  background: linear-gradient(to left, #0000, #000000);
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.81) 0%,
    rgba(0, 0, 0, 0.45) 38.66%,
    rgba(0, 0, 0, 0.92) 99.11%
  ); */
}

.vod-details-gradient {
  /* background: linear-gradient(90deg, rgba(26, 5, 43, 1), rgba(26, 5, 43, 0.9)); */
  background: rgba(21, 5, 34, 0.9);
}

.drawer {
  animation: drawer 0.1s linear;
}

@keyframes drawer {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.gradient-btn {
  background: linear-gradient(
    216.27deg,
    rgba(54, 31, 83, 0.65) -62.47%,
    rgba(88, 20, 89, 0.65) -1.66%,
    rgba(24, 1, 24, 0.65) 52.82%
  );
}

.hide-scroll ::-webkit-scrollbar {
  display: none;
  width: 0;
}

.banner-overlay {
  filter: brightness(0.5);
}

.card:hover .card-overlay-container {
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .banner-gradient {
    height: 50%;
  }

  .vod-details-gradient {
    height: 100%;
  }

  .movies-gradient {
    height: 70%;
  }
}
